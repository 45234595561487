import { computed, inject, Injectable, signal } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

interface PreviewDataState {
	themeId?: string;
	secretCode?: string;
}

const PREVIEW_DATA_SECRET_COOKIE = 'nats_preview_data_secret_code';
const PREVIEW_DATA_THEME_ID_COOKIE = 'nats_preview_data_theme_id';

const PREVIEW_SECRET_CODE_PARAM = 'x-nats-cms-area-preview';
const PREVIEW_THEME_ID_PARAM = 'x-nats-cms-area-preview-theme';

@Injectable({ providedIn: 'root' })
export class PreviewDataStore {
	private cookieService = inject(CookieService);
	private readonly data = signal<PreviewDataState>(this.getInitialValue());

	previewData = this.data.asReadonly();
	readonly isPreviewMode = computed(() => this.data().themeId && this.data().secretCode);

	private getDataFromUrlAndSetPreviewMode(): { themeId?: string; secretCode?: string } | null {
		const { themeId, secretCode } = getPreviewDataFromUrl();
		if (!secretCode || !themeId) return null;
		// if the preview data is in the url, set cookies and return the data
		this.cookieService.set(PREVIEW_DATA_SECRET_COOKIE, secretCode);
		this.cookieService.set(PREVIEW_DATA_THEME_ID_COOKIE, themeId);
		return { themeId, secretCode };
	}

	closePreviewMode() {
		// remove the cookies
		this.cookieService.delete(PREVIEW_DATA_SECRET_COOKIE);
		this.cookieService.delete(PREVIEW_DATA_THEME_ID_COOKIE);

		// remove the query params from the url
		const url = new URL(window.location.href);
		url.searchParams.delete(PREVIEW_SECRET_CODE_PARAM);
		url.searchParams.delete(PREVIEW_THEME_ID_PARAM);

		// update the url
		window.history.replaceState({}, '', url.toString());

		setTimeout(() => {
			// reload the page
			location.reload();
		}, 100);
	}

	private getInitialValue() {
		// if the preview data is in the url, use it
		// otherwise, try to get it from the cookies
		return this.getDataFromUrlAndSetPreviewMode() ?? this.loadFromStorage();
	}

	private loadFromStorage(): { themeId?: string; secretCode?: string } {
		return {
			themeId: this.cookieService.get(PREVIEW_DATA_THEME_ID_COOKIE),
			secretCode: this.cookieService.get(PREVIEW_DATA_SECRET_COOKIE),
		};
	}
}

const getPreviewDataFromUrl = () => {
	const url = new URL(window.location.href);
	const secretCode = url.searchParams.get(PREVIEW_SECRET_CODE_PARAM);
	const themeId = url.searchParams.get(PREVIEW_THEME_ID_PARAM);
	return { secretCode, themeId };
};
